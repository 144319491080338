<template>
  <div class="modal fade" ref="modalColumnas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Columnas CPEs</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el switch deseado para mostrar la columna
          </p>

          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="sitio"
              v-model="columna_sitio"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="sitio">Sitio</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="ip_wan"
              v-model="columna_ip_wan"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="ip_wan">IP WAN</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="mac"
              v-model="columna_mac"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="mac">MAC</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="cliente"
              v-model="columna_cliente"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="cliente">Cliente</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="estado"
              v-model="columna_estado"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="estado">Estado</label>
          </div>
          &nbsp;
          <div class="form-check font-size-15" style="display: inline-block;">
            <input
              class="form-check-input"
              type="checkbox"
              id="servicio"
              v-model="columna_servicio"
              @change="actualizarColumnas()"
            />
            <label class="form-check-label" for="servicio">Servicio</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'MdlColumnas',
  props: {
    sitio: {
      type: Boolean,
      default: true
    },
    ip_wan: {
      type: Boolean,
      default: true
    },
    mac: {
      type: Boolean,
      default: false
    },
    cliente: {
      type: Boolean,
      default: true
    },
    estado: {
      type: Boolean,
      default: false
    },
    servicio: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columna_sitio: this.sitio,
      columna_ip_wan: this.ip_wan,
      columna_mac: this.mac,
      columna_cliente: this.cliente,
      columna_estado: this.estado,
      columna_servicio: this.servicio
    }
  },
  methods: {
    actualizarColumnas: function() {
      var self = this

      self.$emit('actualizar-columnas', {
        sitio: self.columna_sitio,
        ip_wan: self.columna_ip_wan,
        mac: self.columna_mac,
        cliente: self.columna_cliente,
        estado: self.columna_estado,
        servicio: self.columna_servicio
      })
    },
    mostrar() {
      var modal = new Modal(this.$refs.modalColumnas)
      modal.show()
    }
  }
}
</script>
