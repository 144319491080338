<template>
  <div class="modal fade" ref="mdlCambiarIsp" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Cambiar de ISP</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Tienes {{ids.length}} CPEs seleccionados. Elige el nuevo router e ISP que se asignará a cada uno de los CPEs.
          </p>

          <div class="row">
            <div class="col-md-6">
              <label>Router administrador</label>
              <select
                class="form-select"
                v-model="id_router"
                name="id_router"
                @change="refrescarProveedoresInternet()"
              >
                <option :value="null">Ninguno</option>
                <option
                  v-for="router in routers"
                  :value="router.id"
                  :key="router.id"
                >
                  {{ router.nombre }}
                </option>
              </select>
            </div>

            <div class="col-md-6">
              <label>Proveedor de internet</label>
              <select
                class="form-select"
                name="id_isp"
                v-model="id_isp"
                ref="id_isp"
              >
                <option :value="null">Ninguno</option>
                <option v-for="isp in isps" :value="isp.id" :key="isp.id">
                  {{ isp.nombre }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button type="button" class="btn btn-success"
            @click="actualizarIspDeCpes()">
            Cambiar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import RouterSrv from '@/services/RouterSrv'
import CpeSrv from '@/services/CpeSrv.js'

export default {
  name: 'MdlCambiarIsp',
  props: ['ids_cpes_seleccionados'],
  data() {
    return {
      id_router: null,
      id_isp: null,
      routers: [],
      isps: []
    }
  },
  computed: {
    ids: function() {
      return this.ids_cpes_seleccionados
    }
  },
  created() {
    var self = this

    self.cargarRouters().then(response => {
      self.refrescarProveedoresInternet()
    })
  },

  methods: {
    actualizarIspDeCpes() {
      let self = this

      CpeSrv.actualizarIspDeCpes(this.ids, this.id_router, this.id_isp).then(response => {
        let conflictos = response.data.conflictos || []

        if (conflictos.length == 0) 
          iu.msg.success('Se actualizaron los CPEs correctamente')
        else
          setTimeout(function(){
            let mensaje = ''
            conflictos.forEach(function(conflicto){
              mensaje += conflicto.mensaje + '<br/><br/>'
            })
            iu.msg.warning(mensaje)
          }, 500)

        self.$emit('cpes-actualizados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron actualizar los CPEs'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
      
      var modal = Modal.getInstance(this.$refs.mdlCambiarIsp)
      modal.hide()
    },

    cargarRouters: function() {
      var self = this

      let promise = RouterSrv.routersJSON()

      promise.then(response => {
        self.routers = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })

      return promise
    },

    mostrar() {
      var modal = new Modal(this.$refs.mdlCambiarIsp)
      modal.show()
    },

    refrescarProveedoresInternet: function() {
      var self = this

      // Si el CPE no está asignado a un router, limpia la lista y evita continuar
      if (self.id_router == null) {
        self.isps = []
        self.id_isp = null
        return
      }
      
      self.isps = []
      RouterSrv.ispsJSON(this.id_router).then(response => {
        self.isps = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los ISPs a la hora de refrescar los proveedores de internet'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>
